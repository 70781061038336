import { useEffect, useRef } from 'react'
import { THREE_SECONDS } from '../utils/constants'

/**
 * Repeatedly runs a `pollingFunction` until it’s done or the component unmounts
 * - Resolves with the result if `isDonePolling` as true.
 * - Resolves with `{ aborted: true }` if unmounted.
 * - Rejects on errors.
 */

export const useWaitForPolling = (
  pollingFunction,
  interval = THREE_SECONDS
) => {
  const isMountedRef = useRef(true)

  const waitForPollFunction = async (...params) => {
    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const response = await pollingFunction(...params)
          if (!isMountedRef.current) {
            resolve({ ...response, aborted: true })
            return
          }

          if (response.isDonePolling) {
            resolve(response)
          } else {
            setTimeout(poll, interval)
          }
        } catch (error) {
          reject(error)
        }
      }
      poll()
    })
  }

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return waitForPollFunction
}
