import styled from 'styled-components'

export const IFrameWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  max-height: 800px;

  iframe {
    position: relative;
    display: block;
    box-sizing: border-box;
    min-height: ${({ minHeight }) => minHeight || '100%'};
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    z-index: 10;
  }
`

export const StyledLoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
