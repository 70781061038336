import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CafIFrame } from '../../../../molecules/cafIFrame'
import { StyledGenericContentWrapper } from '../../styles'
import { Text, Title } from '../../../../atoms/text'
import { Image } from '../../../../atoms/image'
import icon_selfie_and_id from '../../../../../images/icon_selfie_and_id_gray.svg'
import { Button } from '../../../../atoms/button'
import { doCafIframeCheck } from '../../../../../adapters/auth'
import { FIVE_SECONDS } from '../../../../../utils/constants'
import { toast } from '../../../../atoms'
import { useTranslation } from '../../../../../context/translationProvider'
import { AlreadyDoneContent } from '../../customComponents/alreadyDoneContent'

const SelfieAndIdStep = (props) => {
  const { cafData, onCompleteStep, stepIsDone } = props
  const { translate } = useTranslation()

  const [showIframe, setShowIframe] = useState(false)
  const { onboardingUrl, onboardingId, kycDocumentType } = cafData ?? {}

  const onStart = () => {
    setShowIframe(true)
  }

  const poolIframeCheck = async () => {
    const { ok, data } = await doCafIframeCheck(onboardingId)
    if (ok && data?.status === 'APPROVED') {
      onCompleteStep()
    }
    if (data?.status === 'REJECTED') {
      toast.error(translate('registration.iframe.rejected'))
    }
  }

  useEffect(() => {
    const timerInterval = setInterval(poolIframeCheck, FIVE_SECONDS)
    return function () {
      clearInterval(timerInterval)
    }
  })

  if (showIframe) {
    return (
      <StyledGenericContentWrapper className="iframe-wrapper">
        <CafIFrame shouldUpdateUser={false} cafAltIframeUrl={onboardingUrl} />
      </StyledGenericContentWrapper>
    )
  }

  if (stepIsDone) {
    return (
      <AlreadyDoneContent
        icon={icon_selfie_and_id}
        title={translate('register.alreadyDone.iframe.title')}
        description={translate('register.alreadyDone.iframe.description')}
        onNext={() => onCompleteStep()}
      />
    )
  }

  const translationKey =
    kycDocumentType == 'identificationAndSelfie' ? 'selfieAndId' : 'passport'
  return (
    <StyledGenericContentWrapper>
      <Title level={2}>
        {translate(`registration.${translationKey}.title`)}
      </Title>

      <Image url={icon_selfie_and_id} />
      <Text textAlign="center">
        <span
          dangerouslySetInnerHTML={{
            __html: translate(
              `registration.${translationKey}.normalDescription`
            ),
          }}
        />
      </Text>
      <Button onClick={onStart} expand type="button">
        {translate('common.start')}
      </Button>
    </StyledGenericContentWrapper>
  )
}

SelfieAndIdStep.propTypes = {
  cafData: PropTypes.object,
  onCompleteStep: PropTypes.func,
  stepIsDone: PropTypes.bool,
}

export { SelfieAndIdStep }
