import React from 'react'
import PropTypes from 'prop-types'
import { StyledCheckedIcon, StyledGenericContentWrapper } from '../styles'
import { Text, Title } from '../../../atoms/text'
import { Button } from '../../../atoms/button'
import { useTranslation } from '../../../../context/translationProvider'

const AlreadyDoneContent = (props) => {
  const { title, icon, description, onNext } = props
  const { translate } = useTranslation()

  return (
    <StyledGenericContentWrapper>
      <Title level={2}>{title}</Title>
      <StyledCheckedIcon url={icon} />
      <Text textAlign="center">{description}</Text>

      <Button id="next" expand onClick={onNext} type="button">
        {translate('register.next')}
      </Button>
    </StyledGenericContentWrapper>
  )
}

AlreadyDoneContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  onNext: PropTypes.func,
}

export { AlreadyDoneContent }
