import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import CheckIcon from '../../../images/icon_circle_check_green_dark.svg'
import { MODAL_HEADER_HEIGHT } from '../../atoms/modalHeader/styles'

const modalSize = 'min(730px, 90vh)'

export const RegisterContentWrapperCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${themeStyles.colors.white.main};
  min-height: max-content;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.5em 1.2em;
  gap: 0.5em;
  flex: 1;

  ${(props) =>
    props.addExtraPadding &&
    css`
      padding-top: 1.4em;
    `}

  ${themeMedia.tablet`
    gap: 2em;
    padding: 2em 0em 0 2em;
    flex-direction: row;
    align-items: center;
    max-height: ${modalSize};
    justify-content: center;

    > * {
      padding-bottom: 2em;
      height: 80vh;
      max-height: calc(${modalSize} - 4em);
      width: 500px !important;
      overflow: visible;
    }
    > *:last-child {
      overflow: auto;
      padding-right: 2.7em;
    }
  `}

  ${themeMedia.desktop`
    gap: 4em;
    padding: 3em 0em 0 3em;

    > * {
      max-height: calc(${modalSize} - 6em);
    }
  `}

  > *:last-child {
    display: flex;
    flex-direction: column;
    gap: 1.4em;
  }

  a {
    color: ${themeStyles.colors.black.main};
  }
`

export const StyledContent = styled.div`
  ${RegisterContentWrapperCss}
  ${themeMedia.maxTablet`
    form {
      height: 100%;
      flex: 1;
    }
  `}
`

export const StyledSectionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 0.8rem 0;

  ${themeMedia.maxTablet`
    font-size: 1.2em;
  `}
`

export const StyledRow = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;
`

export const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => props.width || '50%'};
  justify-content: end;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &:not(:last-child) {
      margin-right: 5%;
    }
  }
`

export const StyledEmail = styled.div`
  font-weight: 600;
  margin: 1rem 0;
`

export const StyledEmailAndPasswordStep = styled.section`
  display: flex;
  flex-direction: column;

  button {
    margin: 0.6em 0 1.5em;
  }

  a {
    color: ${themeStyles.colors.accentColors.primary.main};
  }
`

export const StyledGenericContentWrapper = styled.section`
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.9em;
  height: 100%;
  flex: 1;

  img {
    max-width: 100px;
    margin: 0 auto;
  }

  button {
    margin: auto 0 1.5em;
  }

  ${themeMedia.desktop`
    br {
      display: none;
    }
  `}

  &.iframe-wrapper > div,
  &.iframe-wrapper iframe {
    min-height: 680px;
  }

  ${themeMedia.maxTablet`
    &.iframe-wrapper > div,
    &.iframe-wrapper iframe {
      min-height: calc(100vh - ${MODAL_HEADER_HEIGHT});
      height: calc(100vh - ${MODAL_HEADER_HEIGHT});
      margin-bottom: -0.5em;
    } 
  `}
`

export const StyledAddressStep = styled.section`
  #joinUs {
    margin: 0.6em 0 1.5em;
  }
`

export const StyledRecaptchaWrapper = styled.div`
  > * {
    margin: 0 auto 1em;
    width: 80%;
  }
`

export const StyledErrorMessageWrapper = styled.div`
  margin: -1.5em 0 0.5em;
`

// TODO: Remove this component when second step gets depricated
export const StyledSecondSection = styled.section`
  button:first-of-type {
    margin: 1.15em 0;
  }

  select {
    background-color: ${themeStyles.colors.white.main};
  }
`

export const StyledCheckedIcon = styled.div`
  position: relative;
  margin: 0 auto;
  background: url('${(props) => props.url}');
  height: 100px;
  width: 100px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    background-image: url('${CheckIcon}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px;
    height: 36px;
    width: 36px;
  }
`
