import { useEffect, useState } from 'react'
import {
  getCountryCities,
  getCountryRegions,
  getLanguages,
  getNationalities,
} from '../adapters/common'
import { useTranslation } from '../context/translationProvider'

const emptyFunction = () => {}

export const useRegisterData = (props) => {
  const [nationalities, setNationalities] = useState([])
  const {
    isMgaLicense,
    country: previousCountry,
    setValue = emptyFunction,
    setLanguages = emptyFunction,
    setPhoneCodes = emptyFunction,
    setMobilePrefix = emptyFunction,
    setCountry = emptyFunction,
    setRegions = emptyFunction,
    setCities = emptyFunction,
    setWhitelistedCountries = emptyFunction,
  } = props

  const { translate, countriesArray: countries } = useTranslation()

  useEffect(() => {
    loadData(getLanguages, setLanguages)
  }, [])

  useEffect(() => {
    if (isMgaLicense || countries?.length == 0) return
    safeSetCountry('Brazil', true)
  }, [countries])

  useEffect(() => {
    initNationalities()
  }, [])

  const initNationalities = async () => {
    const { ok, data } = await getNationalities()
    if (!ok) return
    const nationalities = data
      .map(({ gentilic, countryId }) => ({ name: gentilic, id: countryId }))
    setNationalities(nationalities)
  }

  const findCountry = (idOrName) => {
    return countries?.find(
      (country) =>
        Number(country?.id) === Number(idOrName) ||
        country?.name?.toUpperCase() === idOrName?.toUpperCase()
    )
  }

  useEffect(() => {
    if (!countries?.length) return
    const codes = []

    countries.forEach((c) => {
      if (c.phone_prefix !== '') {
        codes.push(`+${c.phone_prefix}`)
      }
      c.translated_name = translate(`country.${c.name?.toLowerCase()}`, c.name)
    })
    codes.sort()
    const uniqueCodes = [...new Set(codes)]
    setPhoneCodes(uniqueCodes)
  }, [countries])

  async function loadData(endpoint, setter) {
    const { ok, data } = await endpoint()
    if (ok) setter(data)
  }

  const safeSetCountry = async (countryNameOrId, shouldSetNationality) => {
    const country = findCountry(countryNameOrId)

    if (!country || country.code === previousCountry?.code) return
    setValue('countryCode', country.code)
    setValue('country', country.name)
    if (shouldSetNationality) setValue('nationality', country.id)
    setCountry(country)
    setWhitelistedCountries(`country:${country.code}`)

    setValue('currency', country.default_country_currency)
    if (country.phone_prefix) {
      setValue('mobilePrefix', `+${country.phone_prefix}`)
      setMobilePrefix(`+${country.phone_prefix}`)
    }
    setValue('region', '')
    loadData(() => getCountryRegions(country.id), setRegions)
    loadData(
      () => getCountryCities(country.id),
      (cities) => setCities(cities.sort((a, b) => a.name.localeCompare(b.name)))
    )
  }

  return { safeSetCountry, countries, nationalities }
}
