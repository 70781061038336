import useFeatures from './useFeatures'

export const useReCaptcha = (onSubmit, action) => {
  const isRecaptchaEnabled = useFeatures('recaptcha')

  const waitForGrecaptchaReady = () => {
    if (!window?.grecaptcha?.enterprise?.ready) return
    return new Promise((resolve) => {
      window?.grecaptcha?.enterprise?.ready(resolve)
    })
  }

  return async (params) => {
    if (isRecaptchaEnabled) {
      await waitForGrecaptchaReady()
      const recaptchaToken = await window?.grecaptcha?.enterprise?.execute(
        process.env.GATSBY_RECAPTCHA_KEY,
        { action }
      )
      return await onSubmit({ ...params, recaptchaToken })
    } else {
      return await onSubmit({ ...params, recaptchaToken: null })
    }
  }
}
