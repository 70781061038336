exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blocked-js": () => import("./../../../src/pages/blocked.js" /* webpackChunkName: "component---src-pages-blocked-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-templates-account-bank-accounts-js": () => import("./../../../src/templates/account/bankAccounts.js" /* webpackChunkName: "component---src-templates-account-bank-accounts-js" */),
  "component---src-templates-account-mobile-overview-js": () => import("./../../../src/templates/account/mobileOverview.js" /* webpackChunkName: "component---src-templates-account-mobile-overview-js" */),
  "component---src-templates-account-profile-js": () => import("./../../../src/templates/account/profile.js" /* webpackChunkName: "component---src-templates-account-profile-js" */),
  "component---src-templates-account-responsible-js": () => import("./../../../src/templates/account/responsible.js" /* webpackChunkName: "component---src-templates-account-responsible-js" */),
  "component---src-templates-account-self-exclusion-page-js": () => import("./../../../src/templates/account/selfExclusionPage.js" /* webpackChunkName: "component---src-templates-account-self-exclusion-page-js" */),
  "component---src-templates-account-settings-js": () => import("./../../../src/templates/account/settings.js" /* webpackChunkName: "component---src-templates-account-settings-js" */),
  "component---src-templates-account-transactions-js": () => import("./../../../src/templates/account/transactions.js" /* webpackChunkName: "component---src-templates-account-transactions-js" */),
  "component---src-templates-account-verification-js": () => import("./../../../src/templates/account/verification.js" /* webpackChunkName: "component---src-templates-account-verification-js" */),
  "component---src-templates-casino-casino-category-js": () => import("./../../../src/templates/casino/casinoCategory.js" /* webpackChunkName: "component---src-templates-casino-casino-category-js" */),
  "component---src-templates-casino-casino-game-js": () => import("./../../../src/templates/casino/casinoGame.js" /* webpackChunkName: "component---src-templates-casino-casino-game-js" */),
  "component---src-templates-casino-casino-js": () => import("./../../../src/templates/casino/casino.js" /* webpackChunkName: "component---src-templates-casino-casino-js" */),
  "component---src-templates-common-clear-cache-js": () => import("./../../../src/templates/common/clearCache.js" /* webpackChunkName: "component---src-templates-common-clear-cache-js" */),
  "component---src-templates-common-content-js": () => import("./../../../src/templates/common/content.js" /* webpackChunkName: "component---src-templates-common-content-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-on-boarding-activate-js": () => import("./../../../src/templates/onBoarding/activate.js" /* webpackChunkName: "component---src-templates-on-boarding-activate-js" */),
  "component---src-templates-on-boarding-forgot-password-js": () => import("./../../../src/templates/onBoarding/forgot-password.js" /* webpackChunkName: "component---src-templates-on-boarding-forgot-password-js" */),
  "component---src-templates-on-boarding-login-js": () => import("./../../../src/templates/onBoarding/login.js" /* webpackChunkName: "component---src-templates-on-boarding-login-js" */),
  "component---src-templates-on-boarding-register-js": () => import("./../../../src/templates/onBoarding/register.js" /* webpackChunkName: "component---src-templates-on-boarding-register-js" */),
  "component---src-templates-on-boarding-reset-js": () => import("./../../../src/templates/onBoarding/reset.js" /* webpackChunkName: "component---src-templates-on-boarding-reset-js" */),
  "component---src-templates-on-boarding-verify-2-fa-js": () => import("./../../../src/templates/onBoarding/verify2fa.js" /* webpackChunkName: "component---src-templates-on-boarding-verify-2-fa-js" */),
  "component---src-templates-on-boarding-verify-js": () => import("./../../../src/templates/onBoarding/verify.js" /* webpackChunkName: "component---src-templates-on-boarding-verify-js" */),
  "component---src-templates-payments-deposit-js": () => import("./../../../src/templates/payments/deposit.js" /* webpackChunkName: "component---src-templates-payments-deposit-js" */),
  "component---src-templates-payments-withdraw-js": () => import("./../../../src/templates/payments/withdraw.js" /* webpackChunkName: "component---src-templates-payments-withdraw-js" */),
  "component---src-templates-promotions-promotion-detail-js": () => import("./../../../src/templates/promotions/promotionDetail.js" /* webpackChunkName: "component---src-templates-promotions-promotion-detail-js" */),
  "component---src-templates-promotions-promotions-all-js": () => import("./../../../src/templates/promotions/promotionsAll.js" /* webpackChunkName: "component---src-templates-promotions-promotions-all-js" */),
  "component---src-templates-promotions-promotions-js": () => import("./../../../src/templates/promotions/promotions.js" /* webpackChunkName: "component---src-templates-promotions-promotions-js" */),
  "component---src-templates-providers-provider-detail-js": () => import("./../../../src/templates/providers/providerDetail.js" /* webpackChunkName: "component---src-templates-providers-provider-detail-js" */),
  "component---src-templates-providers-providers-js": () => import("./../../../src/templates/providers/providers.js" /* webpackChunkName: "component---src-templates-providers-providers-js" */),
  "component---src-templates-research-research-js": () => import("./../../../src/templates/research/research.js" /* webpackChunkName: "component---src-templates-research-research-js" */),
  "component---src-templates-sitemap-index-js": () => import("./../../../src/templates/sitemap/index.js" /* webpackChunkName: "component---src-templates-sitemap-index-js" */),
  "component---src-templates-sports-kambi-sports-page-js": () => import("./../../../src/templates/sports/kambiSportsPage.js" /* webpackChunkName: "component---src-templates-sports-kambi-sports-page-js" */)
}

