import styled from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'

const gap = '1.8em'

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: ${gap};
  height: 100%;

  ${themeMedia.desktop`
    br {
      display: none;
    }
  `}

  a {
    color: ${themeStyles.colors.black.main};
  }
`

export const StyledChangeValueLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

export const StyledResendButton = styled.button`
  background: none;
  text-decoration: underline;
`

export const StyledTextWrapper = styled.div`
  ${themeMedia.maxTablet`
    margin-top: -${gap};
    margin-left: auto;

    span, button {
      font-size: 14px;
    }
  `}
`

export const StyledSpinnerWrapper = styled.div`
  margin: 0.6em auto 2.25em;

  ${themeMedia.maxTablet`
    margin: 0.7em auto 2.5em;
  `}
`
