import { apiGET } from '../utils/adapterFunctions'
import { langTags } from '../utils/constants'
import { coreApi } from './base'

const locale = process.env.GATSBY_INTL_LOCALE

export const getCountries = async () => {
  try {
    const { data } = await coreApi.get('/country')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getCurrencies = async () => {
  try {
    const { data } = await coreApi.get('/currency')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getNationalities = async () => {
  const params = { language_code: langTags[locale].toLowerCase() }
  return apiGET('/country-gentilic', { params })
}

export const getLanguages = async () => apiGET('/language')

export const getRegisterCountries = async () => {
  try {
    const {
      data: { data },
    } = await coreApi.get('/country/register')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}

export const getBrazilianBanks = async () => {
  try {
    const { data } = await coreApi.get('/banks')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}

export const getRegistrationDropdownCountries = async () => {
  try {
    const {
      data: { data },
    } = await coreApi.get('/country/registration-dropdown')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}

export const getCountryRegions = async (countryId) => {
  return apiGET(`/country/${countryId}/regions`)
}

export const getCountryCities = async (country_id) => {
  const params = { country_id }
  return apiGET('/city', { params })
}
