import { styled } from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledBottomBar = styled.div`
  ${themeMedia.maxMobile`
        position: fixed;
        width: 100%;
        z-index: 499;
        height: 64px;
        bottom: -1px;
        display: block;
        a {
          text-decoration: none;
        }

        > div {
          height: 100%; 
        }
    `}
  display: none;
`

export const StyledBottomBarAnchor = styled.div`
  ${themeMedia.maxMobile`
      display: block;
      width: 100%;
      height: 0px;
      position: fixed;
      bottom: 63px;
      z-index: 1000;
  `}
  display: none;
`
