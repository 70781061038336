import { useEffect, useState } from 'react'
import { isBrowser } from '../utils/generic'
import storageService from '../services/storageService'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

export const REGISTRATION_STEP_IDS = {
  OLD_EMAIL_AND_PASS: 'OLD_EMAIL_AND_PASS',
  OLD_CONFIRM_NAME: 'OLD_CONFIRM_NAME',
  OLD_ADDRESS: 'OLD_ADDRESS',

  EMAIL_AND_PASS: 'EMAIL_AND_PASS',
  SELFIE_AND_ID: 'SELFIE_AND_ID',
  ADDRESS: 'ADDRESS',
  CONFIRM_PHONE: 'CONFIRM_PHONE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
}

export const REGISTRATION_STEPS = [
  // Steps for the old flow
  {
    id: REGISTRATION_STEP_IDS.OLD_EMAIL_AND_PASS,
    progress: 1,
    hideBackButton: true,
    showUspsBarDesktop: true,
    showUspsBarMobile: true,
    showBannerOnMobile: true,
    featureFlag: 'registration_old_flow',
  },
  {
    id: REGISTRATION_STEP_IDS.OLD_CONFIRM_NAME,
    progress: 2,
    featureFlag: 'registration_old_flow',
  },
  {
    id: REGISTRATION_STEP_IDS.OLD_ADDRESS,
    progress: 3,
    featureFlag: 'registration_old_flow',
  },

  // Steps for the new flow
  // EMAIL_AND_PASS are 100% needed, but the other ones can be toggled on or off
  {
    id: REGISTRATION_STEP_IDS.EMAIL_AND_PASS,
    progress: 1,
    hideBackButton: true,
    showUspsBarDesktop: true,
    showUspsBarMobile: true,
    showBannerOnMobile: true,
    featureFlag: 'registration_new_flow',
  },
  {
    id: REGISTRATION_STEP_IDS.SELFIE_AND_ID,
    progress: 1,
    showUspsBarDesktop: true,
    featureFlag: 'registration_selfie_and_id',
  },
  {
    id: REGISTRATION_STEP_IDS.ADDRESS,
    progress: 2,
    showUspsBarDesktop: true,
    featureFlag: 'registration_new_flow',
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_PHONE,
    progress: 3,
    showUspsBarDesktop: true,
    featureFlag: 'registration_confirm_phone',
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_EMAIL,
    progress: 3,
    showUspsBarDesktop: true,
    featureFlag: 'registration_confirm_email',
  },
]

export const useRegistrationSteps = () => {
  const STEPS_WITH_FLAGS = REGISTRATION_STEPS.map((step) => ({
    ...step,
    isFeatureEnabled: !step.featureFlag || useFeatureIsOn(step.featureFlag),
  }))

  const getFirstIndex = () => {
    for (let i = 0; i < STEPS_WITH_FLAGS.length; i++) {
      if (STEPS_WITH_FLAGS[i]?.isFeatureEnabled) return i
    }
    return 0
  }
  const getLastIndex = () => {
    for (let i = STEPS_WITH_FLAGS.length - 1; i >= 0; i--) {
      if (STEPS_WITH_FLAGS[i]?.isFeatureEnabled) return i
    }
    return 1
  }

  const [formStep, setFormStep] = useState(getFirstIndex())
  const [isLastStep, setIsLastStep] = useState(false)

  const getPrevStepNum = (current) => {
    const newStep = current - 1
    const firstIndex = getFirstIndex()
    if (newStep < firstIndex) return firstIndex

    if (!STEPS_WITH_FLAGS[newStep]?.isFeatureEnabled)
      return getPrevStepNum(newStep)

    return newStep
  }

  const getNextStepNum = (current) => {
    const newStep = current + 1
    const lastIndex = getLastIndex()
    if (newStep >= lastIndex) return lastIndex

    if (!STEPS_WITH_FLAGS[newStep]?.isFeatureEnabled)
      return getNextStepNum(newStep)

    return newStep
  }

  const goBack = () => setFormStep(getPrevStepNum)
  const goToNextStep = () => setFormStep(getNextStepNum)

  useEffect(() => {
    const lastIndex = getLastIndex()
    if (isLastStep && formStep !== lastIndex) {
      setIsLastStep(false)
    } else if (!isLastStep && formStep === lastIndex) {
      setIsLastStep(true)
    }
  }, [isLastStep, formStep])

  const scrollToTop = () => {
    const contentElement =
      document.getElementById('register-modal')?.parentElement?.parentElement ??
      window
    contentElement.scrollTo({ top: 0 })
  }

  useEffect(() => {
    if (!isBrowser()) return
    storageService.setValue('lastFormStep', formStep)
    scrollToTop()
  }, [formStep])

  return { formStep, isLastStep, goToNextStep, goBack }
}
