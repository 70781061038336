import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ArrowDownIcon from '../../../images/arrow_down_icon.svg'
import { Checkbox } from '../../atoms/checkbox'
import {
  StyledShowMoreTandCBtn,
  StyledArrowDown,
  StyledTandCBox,
} from './styles'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useTranslation } from '../../../context/translationProvider'
import { updateAllHrefsIfFbUser } from '../../../helpers/urlHelper'

export const RegistrationCheckmarks = (props) => {
  const { formProperties } = props
  const { register, setValue, getValues } = formProperties
  const { translate } = useTranslation()
  const isBelongHereEnabled = useFeatureIsOn(
    'registration_show_belong_here_checkmark'
  )

  const [showMoreTandC, setShowMoreTandC] = useState(false)
  const [tandcAndMarketing, setTandcAndMarketing] = useState(false)
  const [tandc, setTandc] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [belongHere, setBelongHere] = useState(false)

  const handleCheckAll = (value) => {
    safeSetValue(value, setTandcAndMarketing, 'tandcAndMarketing')
    safeSetValue(value, setTandc, 'tandc')
    safeSetValue(value, setMarketing, 'marketing')
    if (isBelongHereEnabled) safeSetValue(value, setBelongHere, 'belongHere')
  }

  const safeSetValue = (value, setter, key) => {
    setValue(key, value)
    setter(value)
  }

  useEffect(() => {
    if (!tandc || !marketing || (!belongHere && isBelongHereEnabled)) {
      setValue('tandcAndMarketing', false)
      setTandcAndMarketing(false)
    } else if (tandc && marketing && (belongHere || !isBelongHereEnabled)) {
      setValue('tandcAndMarketing', true)
      setTandcAndMarketing(true)
    }
  }, [tandc, marketing, belongHere])

  useEffect(() => {
    const [tandcAndMarketing, tandc, marketing, belongHere] = getValues([
      'tandcAndMarketing',
      'tandc',
      'marketing',
      'belongHere',
    ])
    setTandcAndMarketing(tandcAndMarketing)
    setTandc(tandc)
    setMarketing(marketing)
    setBelongHere(belongHere)
  }, [])

  const createCheckbox = (key, checked, setter, langKey, required = false) => {
    return (
      <Checkbox
        required={required}
        checked={checked}
        formProperties={register(key)}
        onChange={(e) => safeSetValue(e.target.checked, setter, key)}
        id={key}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: updateAllHrefsIfFbUser(translate(`register.${langKey}`)),
          }}
        />
      </Checkbox>
    )
  }
  return (
    <>
      {createCheckbox(
        'tandcAndMarketing',
        tandcAndMarketing,
        handleCheckAll,
        'generalTandc'
      )}

      <StyledShowMoreTandCBtn onClick={() => setShowMoreTandC(!showMoreTandC)}>
        <span id="showMore">
          {showMoreTandC
            ? translate('register.showLessTandC')
            : translate('register.showMoreTandC')}
          <StyledArrowDown
            showMore={showMoreTandC}
            src={ArrowDownIcon}
            alt="Show More"
          />
        </span>
      </StyledShowMoreTandCBtn>

      <StyledTandCBox showMore={showMoreTandC}>
        {createCheckbox('tandc', tandc, setTandc, 'tandc', true)}
        {createCheckbox('marketing', marketing, setMarketing, 'gdpr')}
        {isBelongHereEnabled &&
          createCheckbox(
            'belongHere',
            belongHere,
            setBelongHere,
            'belongHere',
            true
          )}
      </StyledTandCBox>
    </>
  )
}

RegistrationCheckmarks.defaultProps = {
  formProperties: {},
}

RegistrationCheckmarks.propTypes = {
  formProperties: PropTypes.object,
}
